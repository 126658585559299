<template>
    <div id="navigation">
        <span v-if="orangeLogo">
            <img  class="logo-container" src="/images/sungevity-orange.png" />
            <img class="sungevity-impact-text" src="/images/sungevity-international-impact-report.svg" alt="" />
        </span>
        <span v-else>
            <img class="logo-container" src="/images/sungevity-white.png" />
            <img class="sungevity-impact-text" src="/images/sungevity-international-impact-report-white.svg" alt="" />
        </span>
    </div>
</template>

<script>
export default {
    name: "TopNav",
    props: {
        orangeLogo: {
            type: Boolean,
            default: true
        },
    },

    watch: {
        orangeLogo(currentValue, oldValue) {
            if(process.env.NODE_ENV === 'development') console.log(currentValue);
        }
    }
}
</script>