<template>
    <div id="earth">
        <div ref="three" v-bind:class="{ night: isNight, dayFast : isDayFast}" id="threeCanvas"></div>
        <div class="overlay-show" v-bind:class="{ fade: overlayShow }"></div>
        <canvas style="display:none" width="1280px" height="640px" id="worldmap"></canvas>
    </div>
</template>

<script>
    import * as THREE from 'three';
    import * as TWEEN from "@tweenjs/tween.js"; 
    import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

    /*import Stats from 'three/examples/jsm/libs/stats.module';
    import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';*/
            
    export default {
        name: "Earth",
        components: {
        },
        props: {
            zoom: {
                default: true,
                type: Boolean
            },
            daylight: {
                default: false,
                type: Boolean
            },
            housePerspective: {
                default: false,
                type: Boolean
            },
            showAirplanes: {
                default: false,
                type: Boolean
            },
            state: {
                default: 0,
                type: Number
            },
            metric: {
                default: 0,
                type: Number
            },
            hide: {
                default: true,
                type: Boolean
            },
            mobile: {
                default: false,
                type: Boolean
            },
            start: {
                default: false,
                type: Boolean
            }
        },
        data () {
            return {
                clock: new THREE.Clock(),
                //gui: new GUI(),
                scene: null,
                renderer: null,
                windowHalfX: 0,
                windowHalfY: 0,
                mouseX: 0,
                mouseY: 0,
                currentState: 0,
                previousState: 0,
                showDebugGUI: false,
                camera: null,
                cameraWiggle: new THREE.Vector3(0,0,0),
                sun: new THREE.Vector3(),
                axis: new THREE.Vector3(0.1,0.1,0),
                speed: 0.005,
                spotLight: null,
                perspective: true,
                isNight: true,
                hasClouds: true,
                spotLightHelper: null,
                airplanes: null,
                housePosition: new THREE.Vector3(0,0,0),
                loader: new GLTFLoader(),
                sunLight: null,
                sunPlane:null,
                isHousePerspective: false,
                stopUpdating: false,
                previousLook: new THREE.Vector3(0,0,0),
                toggleSoftClouds: false,
                overlayShow: false,
                prevTime: 0,
                firstTime: true,
                lookNow: new THREE.Vector3(0,0,0),
                cloudOpacity: 0.5,
                car: null,
                postprocessing : { enabled: false },
                godrayRenderTargetResolutionMultiplier : 1.0 / 4.0,
                materialDepth: null,
			    clipPosition : new THREE.Vector4(),
                screenSpacePosition : new THREE.Vector3(),
                bgColor: 0x000511,
                sunColor: 0xffee00,
                locationMetric: [0,0],
                isDayFast: false,
                params: {
                    offset: 0.1,
                    posx: -1.5,
                    posy: 2.5, 
                    posz: 3.0,
                    lookatx: 0,
                    lookaty: 1.4,
                    toggleClouds: true,
                    lookatz: 0,
                    rotateEarthX: 0.1,
                    rotateEarthY: 0.1,
                    rotateEarthZ: 0.1,
                    togglePerspective: this.togglePerspective,
                    housePosX: 0.01,
                    housePosY: 0.62,
                    housePosZ: 0.1,
                    houseRX: 0.0002,
                    houseRY: 4,
                    houseRZ: 0.0296,
                    sunlightX: 123.0,
                    sunlightY: -179.0,
                    sunlightZ: -214.0,
                    sunObjectX: 32.0,
                    sunObjectY: -100.1,
                    sunObjectZ: -57.0,
                    sx: 0,
                    sy: 0,
                    exposure: 1,
                    bloomStrength: 1.5,
                    bloomThreshold: 0,
                    bloomRadius: 0,
                    rotateLight: 0,
                    sz: 0,
                    houseScale: 0.001,
                    toggleDaytime: false,
                    toggleHouse: false,
                    toggleEarth: false,
                    toggleAnimations: true,
                    atmo: {
                        g: new THREE.Color("#49AEF5"),
                        c: 0.9,
                        p: 0.0
                    }
                },
                collection: {
                    promises: [],
                    onError: function(error) {
                        if(process.env.NODE_ENV === 'development') console.log(error);
                    },
                    objects: {
                        earth: {
                            model: null,
                            properties: {
                                pos: new THREE.Vector3(0,0,0)
                            },
                            url: "/models/PlanetEarth4.glb",
                            loaded: this.earthLoaded,
                            update: this.earthUpdate,
                            debug: function(folder, object) {
                                /*folder.add( object.model.rotation, 'x', 0, Math.PI * 2, 0.01 ).name( 'Angle X' );
                                folder.add( object.model.rotation, 'y', 0, Math.PI * 2, 0.01 ).name( 'Angle Y' );
                                folder.add( object.model.rotation, 'z', 0, Math.PI * 2, 0.01 ).name( 'Angle Z' );*/
                            }
                        },
                        clouds: {
                            model: null,
                            properties: {

                            },
                            url: "/models/Cloud_1.glb",
                            loaded: this.cloudLoaded,
                            update: this.cloudUpdate,
                            debug: function(folder, object) {
                                /*folder.add( object.model.rotation, 'x', 0, Math.PI * 2, 0.01 ).name( 'Angle X' );
                                folder.add( object.model.rotation, 'y', 0, Math.PI * 2, 0.01 ).name( 'Angle Y' );
                                folder.add( object.model.rotation, 'z', 0, Math.PI * 2, 0.01 ).name( 'Angle Z' );*/
                            }
                        },
                        airplane: {
                            model: null,
                            properties: {

                            },
                            url: "/models/airplanes_multiple.glb",
                            loaded: this.airplaneLoaded,
                            update: this.airplaneUpdate
                        },
                        trees: {
                            model: null,
                            properties: {

                            },
                            url: "/models/trees.glb",
                            loaded: this.treesLoaded,
                            update: this.treesUpdate
                        },
                        car: {
                            model: null,
                            properties: {

                            },
                            url: "/models/car.glb",
                            loaded: this.carLoaded,
                            update: this.carUpdate
                        },
                        house: {
                            model: null,
                            properties: {

                            },
                            url: "/models/House5.glb",
                            loaded: this.houseLoaded,
                            update: this.houseUpdate
                        }
                    }
                }
            };
        },
        mounted() {
            this.init();
        },
        destroyed() {
            //Remove Scene
            for(var item in this.collection.objects) {
                var collectionItem = this.collection.objects[item];
                this.disposeNode(collectionItem.model);
            }
            this.disposeNode(this.scene);
        },
        watch: {
            state(newState, oldState) {
                if(process.env.NODE_ENV === 'development') console.log("New state: " + newState);
                if(process.env.NODE_ENV === 'development') console.log("Previous state: " + oldState);
                this.setState(newState);
            },
            hide(currentValue, oldValue) {
                if(process.env.NODE_ENV === 'development') console.log("Should hide: " + currentValue + " , previously: " + oldValue);
                if(currentValue == true) {
                    this.hideAll();
                } else {
                    if(oldValue == true) {
                        this.showAll();
                    }
                }
            },
            start(currentValue, oldValue) {
                if(currentValue != oldValue) {
                    if(process.env.NODE_ENV === 'development') console.log("Loading is done, setting state of threejs.")
                    this.toggleSunrise();
                    this.setState(this.state, true);
                }
            },
            locationMetric(currentValue, oldValue) {
                if(this.state >= 10 && this.state <= 12) {
                    this.$emit('locationMetric', currentValue);
                }
            },
        },
        methods: {
            init() {
                let _this = this;
				this.renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );
				this.renderer.setPixelRatio( window.devicePixelRatio );
                this.renderer.setSize( window.innerWidth, window.innerHeight );
                this.renderer.toneMapping = THREE.LinearToneMapping;
                this.$refs["three"].appendChild( this.renderer.domElement );
                
                this.renderer.shadowMap.enabled = true;
                this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

				/*this.stats = new Stats();
				this.$refs["three"].appendChild( this.stats.dom );*/

                /*if(!this.showDebugGUI) {
                    this.stats.showPanel(10);
                    this.gui.hide();
                }*/

                this.scene = new THREE.Scene();

                this.camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.01, 2000000 );
                
                this.setCameraPerspective(new THREE.Vector3(0,1.4,0), new THREE.Vector3(-0.9, 2.0, 1.6), true);
                
                window.addEventListener( 'resize', this.onWindowResize, false );

                //SETUP Scene
                for(var item in this.collection.objects) {
                    var collectionItem = this.collection.objects[item];
                    this.collection.promises.push(this.loadModel(collectionItem.url).then(collectionItem.loaded));
                }

               /* const rendererGUI = this.gui.addFolder("renderer");
                rendererGUI.add( _this.renderer, 'toneMappingExposure', 0.1, 2 ).onChange( function ( value ) {
					_this.renderer.toneMappingExposure = Math.pow( value, 4.0 );
                } );*/
                _this.renderer.toneMappingExposure = 0.1;

                Promise.all(this.collection.promises).then( () => {
                    document.addEventListener( 'pointermove', this.onPointerMove, false );
                    this.animate();
                    this.hideAllAnims();

                    // SETUP Debug
                    /*for(var item in this.collection.objects) {
                        var collectionItem = this.collection.objects[item];
                        if(collectionItem.debug != undefined && collectionItem.debug != null) {
                            collectionItem.debug(this.gui.addFolder(item), collectionItem);
                        }
                    }*/

                    _this.$emit("done");
                });
                this.onWindowResize();
            },
            loadModel(url) {
                return new Promise(resolve => {
                    new GLTFLoader().load(process.env.VUE_APP_BASE_URL+url, resolve);
                });
            },
			onWindowResize() {                
                this.windowHalfX = window.innerWidth / 2;
                this.windowHalfY = window.innerHeight / 2;
                
				this.camera.aspect = window.innerWidth / window.innerHeight;
                this.camera.updateProjectionMatrix();
                
                this.renderer.setSize( window.innerWidth, window.innerHeight );
            },
            animate(time) {
                requestAnimationFrame( this.animate );
                //this.stats.begin();
                const dt = (time - this.prevTime) / 1000;

                for(var item in this.collection.objects) {
                    var collectionItem = this.collection.objects[item];
                    collectionItem.update(dt);
                }

                this.spotLight.lookAt(this.collection.objects.earth.model.position);
                this.sunPlane.lookAt(this.camera.position);
                
                this.camera.position.x -= this.cameraWiggle.x;
                this.camera.position.y -= this.cameraWiggle.y;

                this.cameraWiggle.x = ( this.mouseX/1000 - this.cameraWiggle.x/1000  )*0.03;
                this.cameraWiggle.y = ( - ( this.mouseY/1000 ) - this.cameraWiggle.y/1000 ) *0.03;

                this.camera.position.x += this.cameraWiggle.x;
                this.camera.position.y += this.cameraWiggle.y;

                this.render();
                this.prevTime = time;
                //this.stats.end();
            },
			render() {
                this.renderer.render( this.scene, this.camera );
            },
            onPointerMove( event ) {
                if ( event.isPrimary === false ) return;

                this.mouseX = event.clientX - this.windowHalfX;
                this.mouseY = event.clientY - this.windowHalfY;
            },
            setState(newState, noAnim = false) {
                if(newState === 0) {
                    this.setCameraPerspective(new THREE.Vector3(0,1.4,0), new THREE.Vector3(-0.9, 2.0, 1.6), noAnim);
                }

                if(newState === 5) {
                    if(this.mobile) {
                        this.setCameraPerspective(new THREE.Vector3(0.1,0.9,0), new THREE.Vector3(-0.3, 1.05, 0.3), noAnim);
                    } else {
                        this.setCameraPerspective(new THREE.Vector3(0,0.9,0), new THREE.Vector3(-0.3, 1.05, 0.3), noAnim);
                    }
                    this.toggleSofterClouds(0.3);
                    this.animHouseIn();
                } else {
                    this.toggleSofterClouds(0.5);
                    this.animHouseOut();
                }

                if(newState === 20) {
                    this.toggleSofterClouds(0.5);
                    if(this.mobile) {
                        this.setCameraPerspective(new THREE.Vector3(0,1.2,0), new THREE.Vector3(-0.9, 2.0, 1.6), noAnim);
                    } else {
                        this.setCameraPerspective(new THREE.Vector3(0,0,-2.4), new THREE.Vector3(-0.9, 2.0, 1.6), noAnim);
                    }
                }

                if(newState === 10 ) {
                    this.toggleSofterClouds(0.2);
                    if(this.mobile) {
                        this.setCameraPerspective(new THREE.Vector3(0,1.2,0), new THREE.Vector3(-0.9, 2.0, 1.6), noAnim);
                    } else {
                        this.setCameraPerspective(new THREE.Vector3(0.5,0.5,0.75), new THREE.Vector3(-1.7, 3, 3.5), noAnim);
                    }
                    this.sunLight.color = new THREE.Color(255,255,255);
                }
                if(newState === 11 ) {
                    this.toggleSofterClouds(0.2);
                    if(this.mobile) {
                        this.setCameraPerspective(new THREE.Vector3(0,0.5,0), new THREE.Vector3(-1.7, 3.0, 3.5), noAnim);
                    } else {
                        this.setCameraPerspective(new THREE.Vector3(-0.85,0.5,0.1), new THREE.Vector3(-1.7, 3, 3.5), noAnim);
                    }
                    this.sunLight.color = new THREE.Color(255,255,255);
                }

                if(newState === 12 ) {
                    this.toggleSofterClouds(0.2);
                    if(this.mobile) {
                        this.setCameraPerspective(new THREE.Vector3(0,0,0), new THREE.Vector3(-1.7, 2.0, 3.5), noAnim);
                    } else {
                        this.setCameraPerspective(new THREE.Vector3(0.5,0.5,0.75), new THREE.Vector3(-1.7, 3, 3.5), noAnim);
                    }

                    this.sunLight.color = new THREE.Color(255,255,255);
                }

                if(newState === 10) {
                    this.animTreesIn();
                } else {
                    this.animTreesOut();
                }

                if(newState === 11) {
                    this.animAirplanesIn();
                } else {
                    this.animAirplanesOut();
                }

                if(newState === 12) {
                    this.animCarIn();
                } else {
                    this.animCarOut();
                }
            },
            toggleHouse() {
                let _this = this;
                if(this.isHousePerspective) {
                    this.setCameraPerspective(this.house, new THREE.Vector3(-0.3,1.1,0.5));
                    this.isHousePerspective = false;
                } else {
                    this.setCameraPerspective(this.earth, new THREE.Vector3());
                    this.isHousePerspective = true;
                }
            },
            hideAll() {
                /*this.collection.objects.trees.model.visible = false;
                this.collection.objects.earth.model.visible = false;
                this.collection.objects.clouds.model.visible = false;
                this.sunPlane.visible = false;*/
                this.overlayShow = true;
            },
            showAll() {
               /* this.collection.objects.trees.model.visible = true;
                this.collection.objects.earth.model.visible = true;
                this.collection.objects.clouds.model.visible = true;
                this.sunPlane.visible = true;*/
                this.overlayShow = false;
            },
            hideAllAnims() {
                this.collection.objects.airplane.model.visible = false;
                //this.collection.objects.trees.model.visible = false;
                //this.collection.objects.house.model.visible = false;
                this.collection.objects.car.model.visible = false;
            },
            animHouseOut() {
                new TWEEN.Tween(this.collection.objects.house.model.scale)
                .to({x: 0, y: 0, z: 0})
                .easing(TWEEN.Easing.Bounce.Out)
                .start();
            },
            animHouseIn() {
                new TWEEN.Tween(this.collection.objects.house.model.scale)
                .to({x: 1, y: 1, z: 1})
                .easing(TWEEN.Easing.Bounce.In)
                .start();
            },
            animAirplanesIn() {
                if(this.collection.objects.airplane === undefined) return;

                this.collection.objects.airplane.model.visible = true;
                this.collection.objects.airplane.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        new TWEEN.Tween(child.scale)
                        .to({x: 1, y: 1, z: 1})
                        .easing(TWEEN.Easing.Bounce.In)
                        .start();
                    }
                });
                for(var a=0; a < this.collection.objects.airplane.clips.length; a++) {
                    var clip = this.collection.objects.airplane.clips[a];
                    var action = this.collection.objects.airplane.mixer.clipAction(clip);
                    action.reset();
                    action.setEffectiveTimeScale(1);
                    action.setLoop(THREE.LoopRepeat);
                    action.clampWhenFinished = true;
                    action.play();
                }
            },
            animAirplanesOut() {
                if(this.collection.objects.airplane.model === undefined || this.collection.objects.airplane.model === null) return;
                this.collection.objects.airplane.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        new TWEEN.Tween(child.scale)
                        .to({x: 0, y: 0, z: 0})
                        .easing(TWEEN.Easing.Bounce.Out)
                        .start();
                    }
                });
            },
            animTreesIn() {
                this.collection.objects.trees.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        new TWEEN.Tween(child.scale)
                        .to({x: 1, y: 1, z: 1})
                        .easing(TWEEN.Easing.Bounce.InOut)
                        .start();
                    }
                });
            },
            animTreesOut() {
                this.collection.objects.trees.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        new TWEEN.Tween(child.scale)
                        .to({x: 0, y: 0, z: 0})
                        .easing(TWEEN.Easing.Bounce.Out)
                        .start();
                    }
                });
            },
            animCarIn() {
                if(this.collection.objects.car === undefined) return;
                this.collection.objects.car.model.visible = true;
                this.collection.objects.car.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        new TWEEN.Tween(child.scale)
                        .to({x: 1, y: 1, z: 1})
                        .easing(TWEEN.Easing.Bounce.In)
                        .start();
                    }
                });
                var clip = this.collection.objects.car.clips.find((clip) => clip.name === 'CarAction');
                var action = this.collection.objects.car.mixer.clipAction(clip);
                action.reset();
                action.setLoop(THREE.LoopRepeat);
                action.play();
            },
            animCarOut() {
                if(this.collection.objects.car.model === undefined || this.collection.objects.car.model === null) return;
                this.collection.objects.car.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        new TWEEN.Tween(child.scale)
                        .to({x: 0, y: 0, z: 0})
                        .easing(TWEEN.Easing.Bounce.In)
                        .start();
                    }
                });
            },
            toggleSunrise() {
                this.isNight = !this.isNight;
                var noAnim = (this.state != 0 && this.state != 20);
                if(noAnim) {
                    this.isDayFast = true;
                }
                this.changeSunPosition(this.isNight, noAnim);
            },
            toggleSofterClouds(fOpacity) {
                if(this.collection.objects.clouds.model === undefined) return;
                for(var c = 0; c < this.collection.objects.clouds.model.children.length; c++) {
                    let obj = this.collection.objects.clouds.model.children[c];
                    new TWEEN.Tween(obj.material)
                    .to({ opacity: fOpacity }, 1000)
                    .easing(TWEEN.Easing.Quartic.InOut)
                    .start();
                }
            },
            changeSunPositionNoAnim(isSunrise) { //TODO TWEEN AND SET ANIM TO FALSE IF PRESTATE (unless toggling)
                if(!isSunrise) {
                    var position = {x: 0, y: 150, z: 0, colorr: 100, colorg: 100, colorb: 100, sx: 0, sy: -34, sz: 36, sunplanex: 32, sunplaney: -11, sunplanez: -57, ambIntensity: 18 };
                    this.spotLight.position.set(position.x, position.y, position.z);
                    this.spotLight.color = new THREE.Color(position.colorr, position.colorg, position.colorb);
                    this.spotLight.lookAt(this.collection.objects.earth.model.position);
                    this.ambLight.intensity = position.ambIntensity;
                    this.sunLight.position.set(position.sx, position.sy, position.sz);
                    this.sunPlane.position.set(position.sunplanex, position.sunplaney, position.sunplanez);
                } else {
                    var position = {x: 123, y: -179, z: -214, colorr: 0, colorb: 0, colorg: 0, colorg: 0, sx: 0, sy: 0, sz: 0, sunplanex: 32, sunplaney: -100, sunplanez: -57, ambIntensity: 0 };
                    this.spotLight.position.set(position.x, position.y, position.z);
                    this.spotLight.color = new THREE.Color(position.colorr, position.colorg, position.colorb);
                    this.spotLight.lookAt(_this.collection.objects.earth.model.position);
                    this.ambLight.intensity = position.ambIntensity;
                    this.sunLight.color = new THREE.Color(position.colorr, position.colorg, position.colorb);
                    this.sunLight.position.set(position.sx, position.sy, position.sz);
                    this.sunPlane.position.set(position.sunplanex, position.sunplaney, position.sunplanez);
                }
            },
            changeSunPosition(isSunrise, noAnim = false) {
                if(noAnim) { this.changeSunPositionNoAnim(isSunrise);  return; }
                let _this = this;
                if(!isSunrise) {
                    const position = { x: 123, y: -179, z: -214, colorr: 0, colorb: 0, colorg: 0, sx: 0, sy: 0, sz: 0, sunplanex: 32, sunplaney: -100, sunplanez: -57, ambIntensity: 0 };
                    new TWEEN.Tween(position)
                    .to({x: 0, y: 150, z: 0, colorr: 100, colorg: 100, colorb: 100, sx: 0, sy: -34, sz: 36, sunplanex: 32, sunplaney: -11, sunplanez: -57, ambIntensity: 18 }, 4000)
                    .easing(TWEEN.Easing.Quartic.InOut)
                    .onUpdate(() => {
                        _this.spotLight.position.set(position.x, position.y, position.z);
                        _this.spotLight.color = new THREE.Color(position.colorr, position.colorg, position.colorb);
                        _this.spotLight.lookAt(_this.collection.objects.earth.model.position);

                        _this.ambLight.intensity = position.ambIntensity;
                        
                        _this.sunLight.position.set(position.sx, position.sy, position.sz);
                        _this.sunPlane.position.set(position.sunplanex, position.sunplaney, position.sunplanez);
                    })
                    .start();
                } else {
                    const position = { x: 0, y: 150, z: 0, colorr: 100, colorg: 100, colorb: 100, sx: 0, sy: -34, sz: 36, sunplanex: 32, sunplaney: -11, sunplanez: -57, ambIntensity: 18 };
                    new TWEEN.Tween(position)
                    .to({x: 123, y: -179, z: -214, colorr: 0, colorb: 0, colorg: 0, colorg: 0, sx: 0, sy: 0, sz: 0, sunplanex: 32, sunplaney: -100, sunplanez: -57, ambIntensity: 0 }, 4000)
                    .easing(TWEEN.Easing.Quartic.InOut)
                    .onUpdate(() => {
                        _this.spotLight.position.set(position.x, position.y, position.z);
                        _this.spotLight.color = new THREE.Color(position.colorr, position.colorg, position.colorb);
                        _this.spotLight.lookAt(_this.collection.objects.earth.model.position);

                        _this.ambLight.intensity = position.ambIntensity;

                        _this.sunLight.color = new THREE.Color(position.colorr, position.colorg, position.colorb);
                        _this.sunLight.position.set(position.sx, position.sy, position.sz);
                        _this.sunPlane.position.set(position.sunplanex, position.sunplaney, position.sunplanez);
                    })
                    .start();
                }
            },
            setCameraLookAt(look) {
                this.camera.lookAt(look);
            },
            setCameraPerspectiveNoAnim(look, position) {
                this.camera.position.set(position.x, position.y, position.z);
                this.previousLook.set(look.x, look.y, look.z);
                this.setCameraLookAt(this.previousLook);
            },
            setCameraPerspective(look, position, noAnim = false) {
                if(noAnim) {
                    this.setCameraPerspectiveNoAnim(look, position);
                } else {
                    let _this = this;
                    new TWEEN.Tween(this.camera.position)
                    .to({ x: position.x, y: position.y, z: position.z })
                    .easing(TWEEN.Easing.Quartic.InOut)
                    .start();
                    new TWEEN.Tween(this.previousLook)
                    .to({x: look.x, y:look.y, z:look.z})
                    .onUpdate(() => {
                        _this.camera.lookAt(_this.previousLook);
                    })
                    .easing(TWEEN.Easing.Quartic.InOut)
                    .start();
                }
            },
            carUpdate(dt) {
                if(this.collection.objects.car.mixer !== undefined) {
                    this.collection.objects.car.mixer.update( dt );
                }
            },
            carLoaded(gltf){
                const scene = gltf.scene || gltf.scene[0];
                const clips = gltf.animations;

                this.collection.objects.car.model = scene;
                this.collection.objects.car.mixer = new THREE.AnimationMixer(this.collection.objects.car.model);
                this.collection.objects.car.clips = gltf.animations;

                this.collection.objects.car.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        child.castShadow = true;
                        child.receiveShadow = false;
                    }
                    if ( child.material ) child.material.metalness = 0;
                });
                
                this.scene.add(this.collection.objects.car.model);
                this.collection.objects.car.model.rotation.set(-0.42, 0.3, -0.2);
            },
            cloudUpdate() {
                this.collection.objects.clouds.model.rotateX( 0.0005 );
                this.collection.objects.clouds.model.rotateY( 0.0005 );
            },
            cloudLoaded(gltf) {
                this.collection.objects.clouds.model = new THREE.Group();

                var cloudObj = null;
                cloudObj = gltf.scene.children[0];
                cloudObj.castShadow = true;
                cloudObj.receiveShadow = false;
                cloudObj.scale.set(cloudObj.scale.x*0.05, cloudObj.scale.y*0.05, cloudObj.scale.z*0.05);
                cloudObj.material = new THREE.MeshStandardMaterial( {color: new THREE.Color("rgb(255, 255, 255)"), transparent: true, opacity: 0.5, roughness: 0} );
                
                for(var i=0; i<100; i++) {
                    var cloud = cloudObj.clone();
                    cloud.position.copy(new THREE.Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5 ).normalize().multiplyScalar( 0.9 + 0.06 ));
                    
                    /*
                    if(Math.random() > 0.8) {
                        var value = Math.random();
                        cloud.material.color = new THREE.Color(value,value,value);
                    }
                    */

                    cloud.lookAt(new THREE.Vector3(0,0,0));
                    this.collection.objects.clouds.model.add(cloud);
                }
                this.scene.add(this.collection.objects.clouds.model);
            },
            airplaneUpdate(dt) {
                if(this.collection.objects.airplane.mixer !== undefined) {
                    this.collection.objects.airplane.mixer.update( dt );
                }
            },
            airplaneLoaded(gltf) {
                let _this = this;
                const scene = gltf.scene || gltf.scene[0];
                const clips = gltf.animations || [];
                

                this.collection.objects.airplane.model = scene;

                this.collection.objects.airplane.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        child.castShadow = true;
                        child.receiveShadow = false;
                    }
                    if ( child.material ) child.material.metalness = 0;
                });

                this.collection.objects.airplane.mixer = new THREE.AnimationMixer(scene);
                this.collection.objects.airplane.clips = clips;
                this.scene.add(this.collection.objects.airplane.model);
                this.collection.objects.airplane.model.rotation.set(-0.42, 0.3, -0.2);
            },
            treesUpdate(dt) {
                /*if(this.collection.objects.trees.mixer !== undefined) {
                    this.collection.objects.trees.mixer.update( dt );
                }*/
            },
            treesLoaded(gltf) {
                const scene = gltf.scene || gltf.scene[0];
                const clips = gltf.animations || [];
                let _this = this;

                this.collection.objects.trees.model = scene;

                this.collection.objects.trees.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        child.castShadow = true;
                        child.receiveShadow = false;
                    }
                    if ( child.material ) child.material.metalness = 0;
                });
                
                this.collection.objects.trees.model.rotation.set(-0.42, 0.3, -0.2);

                this.scene.add(this.collection.objects.trees.model);
                this.collection.objects.trees.model.visible = true;
            },
            earthUpdate() {
                var p = this.collection.objects.earth.model.position.clone();
                p.y = p.y + 1;
                p.project(this.camera);
                this.locationMetric = [
                    Math.round(( p.x + 1) * this.windowHalfX),
                    Math.round(- ( p.y - 1) * this.windowHalfY)
                ];
            },
            earthLoaded(gltf) {
                if(process.env.NODE_ENV === 'development') console.log("The earth is loading...");

                this.createSun();

                this.collection.objects.earth.model = gltf.scene.getObjectByName("PlanetEarth001");
                this.scene.add( this.collection.objects.earth.model );

                this.collection.objects.earth.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        child.castShadow = false;
                        child.receiveShadow = true;
                    }
                    if ( child.material ) child.material.metalness = 0;
                });

                this.collection.objects.earth.model.position.set(0,0,0);
                this.collection.objects.earth.model.rotation.set(-0.42, 0.3, -0.2);
                this.collection.objects.earth.model.position.set(this.collection.objects.earth.properties.pos.x,
                                                                this.collection.objects.earth.properties.pos.y,
                                                                this.collection.objects.earth.properties.pos.z);
            },
            houseUpdate(dt) {
                if(this.collection.objects.house.mixer !== undefined) {
                    this.collection.objects.house.mixer.update( dt );
                }
            },
            houseLoaded(gltf) {
                const scene = gltf.scene || gltf.scene[0];
                const clips = gltf.animations || [];
                
                this.collection.objects.house.model = scene;
                this.collection.objects.house.mixer = new THREE.AnimationMixer(scene);
                this.collection.objects.house.clips = clips;

                this.collection.objects.house.model.traverse( function( child ) { 
                    if ( child.isMesh ) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                    if ( child.material ) child.material.metalness = 0;
                });

                this.scene.add(this.collection.objects.house.model);
                this.collection.objects.house.model.rotation.set(-0.42, 0.3, -0.2);
            },
            createSun() {
                this.sunLight = new THREE.PointLight( new THREE.Color("rgba(200,200,200)"), 0.6, 3 );
                this.sunLight.castShadow = false;
                this.scene.add(this.sunLight);

                this.ambLight = new THREE.AmbientLight(0x666666, 18);
                this.ambLight.position.set(0,2,0);
                this.scene.add(this.ambLight);

                /*var shadow = this.gui.addFolder("ambient");
                shadow.add( this.ambLight, 'intensity', 0, 100, 0.01 ).name( 'Intensity' );*/

                this.spotLight = new THREE.SpotLight( new THREE.Color("rgba(200,200,200)"), 0.26, 5 );
                this.spotLight.position.set( 0, 0, 0 );

                this.spotLight.castShadow = true;
                this.spotLight.penumbra = 0.01;
                this.spotLight.angle = 0.01;
                this.spotLight.decay = 0.038;
                this.spotLight.distance = 176.62;

                this.spotLight.shadow.mapSize.width = 2048;
                this.spotLight.shadow.mapSize.height = 2048;
                this.spotLight.shadow.camera.near = 0.01;
                this.spotLight.shadow.bias = 0.00001;
                this.spotLight.shadow.camera.far = 300;
                this.spotLight.shadow.camera.fov = 90;

               /* var shadow = this.gui.addFolder("shadow");
                shadow.add( this.spotLight, 'penumbra', 0, 1, 0.01 ).name( 'Penumbra' );
                shadow.add( this.spotLight, 'angle', 0, 1, 0.01 ).name( 'Angle' );
                shadow.add( this.spotLight, 'decay', 0, 1, 0.001 ).name( 'Decay' );
                shadow.add( this.spotLight, 'distance', 0, 300, 0.01 ).name( 'Decay' );
                shadow.add(this.sunLight, 'visible').name( 'Toggle extra light' );
                shadow.add( this.spotLight.shadow.camera, 'far', 0, 300, 1 ).name( 'camera.far' );*/

                
                this.scene.add(this.spotLight);
                this.scene.add(this.spotLight.target);

                const textureLoader = new THREE.TextureLoader();
                var sunTexture = textureLoader.load( "/images/lensflare/lensflare0.png" );

                var sunMaterial = new THREE.MeshStandardMaterial({ map : sunTexture });
                sunMaterial.transparent = true;

                this.sunPlane = new THREE.Mesh(new THREE.PlaneGeometry(30, 30), sunMaterial);
                this.sunPlane.color = new THREE.Color(144,0,0);

                this.scene.add(this.sunPlane);

                //setting initial position
                this.spotLight.position.set(123, -179,  -214);
                this.spotLight.color = new THREE.Color(0,0,0);

                this.ambLight.intensity = 0;
                
                this.sunLight.position.set(0,0,0);
                this.sunPlane.position.set(32, -100, -57);
            },

            disposeNode(node) {
                if (node instanceof THREE.Mesh) {
                    if (node.geometry) {
                        node.geometry.dispose ();
                    }

                    if (node.material) {
                        if (node.material instanceof THREE.MeshFaceMaterial) {
                            $.each (node.material.materials, function (idx, mtrl) {
                                if (mtrl.map)               mtrl.map.dispose ();
                                if (mtrl.lightMap)          mtrl.lightMap.dispose ();
                                if (mtrl.bumpMap)           mtrl.bumpMap.dispose ();
                                if (mtrl.normalMap)         mtrl.normalMap.dispose ();
                                if (mtrl.specularMap)       mtrl.specularMap.dispose ();
                                if (mtrl.envMap)            mtrl.envMap.dispose ();
                                if (mtrl.alphaMap)          mtrl.alphaMap.dispose();
                                if (mtrl.aoMap)             mtrl.aoMap.dispose();
                                if (mtrl.displacementMap)   mtrl.displacementMap.dispose();
                                if (mtrl.emissiveMap)       mtrl.emissiveMap.dispose();
                                if (mtrl.gradientMap)       mtrl.gradientMap.dispose();
                                if (mtrl.metalnessMap)      mtrl.metalnessMap.dispose();
                                if (mtrl.roughnessMap)      mtrl.roughnessMap.dispose();

                                mtrl.dispose ();    // disposes any programs associated with the material
                            });
                        } else {
                            if (node.material.map)              node.material.map.dispose ();
                            if (node.material.lightMap)         node.material.lightMap.dispose ();
                            if (node.material.bumpMap)          node.material.bumpMap.dispose ();
                            if (node.material.normalMap)        node.material.normalMap.dispose ();
                            if (node.material.specularMap)      node.material.specularMap.dispose ();
                            if (node.material.envMap)           node.material.envMap.dispose ();
                            if (node.material.alphaMap)         node.material.alphaMap.dispose();
                            if (node.material.aoMap)            node.material.aoMap.dispose();
                            if (node.material.displacementMap)  node.material.displacementMap.dispose();
                            if (node.material.emissiveMap)      node.material.emissiveMap.dispose();
                            if (node.material.gradientMap)      node.material.gradientMap.dispose();
                            if (node.material.metalnessMap)     node.material.metalnessMap.dispose();
                            if (node.material.roughnessMap)     node.material.roughnessMap.dispose();

                            node.material.dispose ();   // disposes any programs associated with the material
                        }
                    }
                }
            },
        }
    }
</script>