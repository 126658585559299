import VueRouter from "vue-router";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: "home",
            component: () => import("./App.vue"),
        },
        // {
        //     path: '/u/:slug',
        //     name: "slug",
        //     component: () => import("./App.vue"),
        // },
    ]
})

export default router;