<template>
    <div id="loading" v-bind:class="{'fade': counterDone}">
        <div class="logo">
            <div class="rotateLogo">
                <img class="rotating" src="/images/sungevity-sun-white.png" />
            </div>
            <div class="textLogo">
                <img src="/images/sungevity-text-white.png" />
            </div>
            <p>loading <AnimatedCounter v-if="progress > 0" :tweenDuration="2000" :value="progress" :emit="true" @complete="done"></AnimatedCounter><span v-else>0</span>%</p>
            <div class="clear"></div>
        </div>
        <hr v-bind:style="{width: progress+'%'}" />
    </div>
</template>

<script>
import AnimatedCounter from "../components/AnimatedCounter"

export default {
    components: {
        AnimatedCounter
    },
    props: {
        progress: {
            type: Number,
            default: 0
        }
    },
    data: () => {
        return {
            counterDone: false 
        };
    },
    methods: {
        done() {
            if(process.env.NODE_ENV === 'development') console.log("Loading complete");
            this.counterDone = true;
            this.$emit("complete");
        }
    }
}
</script>