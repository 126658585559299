<template>
    <div class="embleem-container" :metric="metric" v-bind:class="{'show': started}" v-bind:style="{'left': position[0]+'px', 'top' : position[1]+'px'}">
        <img src="/images/embleem-down.svg" alt="" class="embleem-img" />
        <div class="embleem-text">
            <h1><AnimatedCounter :value="metrics[metric].value" :formatter="metrics[metric].formatter" :append="metrics[metric].append" :reset="true"></AnimatedCounter></h1>
            <p v-html="metrics[metric].description"></p>
        </div>
    </div>
</template>

<script>
import AnimatedCounter from './AnimatedCounter'

import Visitor from './Visitor'

export default {
    name: "Embleem",
    components: {
        AnimatedCounter
    },
    props: {
        metric: {
            type: Number,
            default: 0
        },
        location: {
            type: Array,
            default: [0,0]
        },
        mobile: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        let _this = this;
        setTimeout(function() {
            _this.started = true;
        },100);
    },
    computed: {
        position: function() {
            return [this.location[0]-this.offset[0],this.location[1]-this.offset[1]+(this.mobile?1:0)*50];
        }
    },
    data () {
      return {
            started: false,
            offset: [80,134],
            metrics: [
                {
                    value: 123924,
                    description: "bomen <br/> planten",
                    append: "",
                    formatter: (a) => {
                        return Visitor.fN(a.toFixed(0));
                    }
                },
                {
                    value: 58648,
                    description: "retourtjes Amsterdam-New York",
                    append: "",
                    formatter: (a) => {
                        return Visitor.fN(a.toFixed(0));
                    }
                },
                {
                    value: 1.6,
                    description: "kilometers autorijden",
                    append: "miljard",
                    formatter: (a) => {
                        return Visitor.fN(a.toFixed(2));
                    }
                }
            ],
        }
    }
}
</script>