require('./bootstrap');

window.Vue = require('vue').default;

import App from './App.vue';
import './fullpage-extensions.js';
import 'fullpage.js/vendors/scrolloverflow.min';
import VueFullPage from 'vue-fullpage.js';

import VueRouter from 'vue-router';
import router from "./router";

import VueSocialSharing from 'vue-social-sharing'

import VueTypedJs from 'vue-typed-js';

Vue.use(VueRouter);
Vue.use(VueFullPage);
Vue.use(VueSocialSharing);
Vue.use(VueTypedJs);

Vue.config.productionTip = false;

new Vue({
    router: router,
    render: h => h(App)
  }).$mount("#app");