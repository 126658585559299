<template>
    <div class="section fp-auto-height-responsive generatie-zon">
        <div class="slide" >
            <div class="container-fluid content">
                <div class="row">
                    <div class="col col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 d-flex offset-md-1">
                        <div class="summary align-self-lg-center">
                            <h1>Generatie Zon</h1>
                            <div class="summary-body">
                                <p>De klimaatcrisis is zo onnodig! Als iedereen zonnestroom gebruikt, lossen we een kwart van ons CO<sub>2</sub>-probleem op. Dus willen wij zonnestroom de norm maken. Het liefst wekken we op elk geschikt dak zonnestroom op, maar met een kwart zijn we al blij.</p>
                                <p><strong>Want als een kwart van een samenleving iets nieuws omarmt, volgt de rest vanzelf.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AnimatedCounter from "../components/AnimatedCounter"

    export default {
        name: "GeneratieZon",

        components: {
            AnimatedCounter
        },

        methods: {
            
        },

        data() {
            return {
                sourceUrl: "",
                sourceLogo: "",
            }
        },
        
        props: {
            active: {
                type: Boolean,
                default: true
            }
        }
    }
</script>