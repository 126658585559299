<template>
    <div class="row d-flex share-button">
        <div class="col-12">
            <div class="flex-row d-flex">
                <div id="social-share" class="dropdown" v-bind:class="{'left': left}">
                    <button class="btn btn-primary btn-lg dropdown-toggle" v-bind:class="{'normal': normal}" type="button" @click="$emit('click')">
                        <p class="bolder" v-html="label"></p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SocialShareButton",

        components: {
            
        },
        methods: {
            
        },
        watch: {
            
        },
        props: {
            label: {
                type: String,
                default: "Deel je <br /> impact!"
            },
            normal: {
                type: Boolean,
                default: false
            },
            left: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            
        },
        data() {
            return {
                
            };
        },
    }
</script>