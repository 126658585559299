<template>
    <div class="section fp-auto-height-responsive generatie-zon">
        <div class="slide">
            <div class="container-fluid content">
                <div class="row px-3 pt-5 pt-md-0 pb-5 pb-md-0">
                    <div class="row col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 d-flex offset-md-1">
                        <div class="summary align-self-lg-center">
                            <h1>Standje Turbo</h1>
                            <div class="summary-body">
                                <p>
                                    Generatie Zon groeit steeds harder. In dertig jaar tijd kregen 1 miljoen huizen zonnepanelen. En nu? Komen er in 5 jaar nog een miljoen bij. In 2025 is zonnestroom de norm. 
                                </p>
                                <!-- <p>
                                    <strong><animated-counter :tweenDuration="animDuration*1000" :value="active ? 30 : 0"></animated-counter> jaar</strong> heeft het ons gekost om hier te komen.
                                </p>
                                <p>
                                    <strong>Binnen <animated-counter :value="active ? 5 : 0"></animated-counter> jaar</strong> verwachten we de 2 miljoen te bereiken, een kwart van Nederland!
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class='graph' v-if="!mobile">
                    <svg v-if="animMarkerIndex > 0" :viewBox="viewbox" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" version="1.1" class="svg-container">
                        <g v-if="false" id='chart-container'>
                            <path stroke-dashoffset="3000" fill="none" stroke-dasharray="3000" stroke-width="5" strok-linecap="round" stroke="#fde900" :d="d">
                                <animate attributeName="stroke-dashoffset" from="3000" to="0" :dur="`${animDuration*3}s`" ref="toCross" />
                            </path>
                        </g>

                        <g id="chart">
                            <g v-for="(line, index) in line1Data" :key="index">
                                <line v-if="index > 0 && index != line1Data.length-1" :x1="line1Data[index-1][0]*width" :y1="line1Data[index-1][1]*height" :x2="line1Data[index-1][0]*width" :y2="line1Data[index-1][1]*height" stroke-width="8" stroke="#fde900">
                                    <animate :ref="`anim-${index}-x2`" attributeName="x2" :from="line1Data[index-1][0]*width" :to="line[0]*width" :begin="`${index}s`" dur="1s"  fill="freeze"/>
                                    <animate :ref="`anim-${index}-y2`" attributeName="y2" :from="line1Data[index-1][1]*height" :to="line[1]*height" :begin="`${index}s`" dur="1s" fill="freeze" />
                                </line>
                                <line v-if="index == line1Data.length-1" :x1="line1Data[index-1][0]*width" :y1="line1Data[index-1][1]*height" :x2="line1Data[index-1][0]*width" :y2="line1Data[index-1][1]*height" stroke-width="8" stroke-dasharray="5,5" stroke="#fde900">
                                    <animate :ref="`anim-${index}-x2`" attributeName="x2" :from="line1Data[index-1][0]*width" :to="line[0]*width" :begin="`${index}s`" dur="1s"  fill="freeze"/>
                                    <animate :ref="`anim-${index}-y2`" attributeName="y2" :from="line1Data[index-1][1]*height" :to="line[1]*height" :begin="`${index}s`" dur="1s" fill="freeze" />
                                </line>
                            </g>
                        </g>
                        <g id="markers">
                            <g v-for="(marker,index) in markers" :key="index">
                                <line class="line-info" v-bind:class="{'show': animMarkerIndex > marker.anim}" stroke="#fff" stroke-width="2" stroke-dasharray="5,5" :x1="line1Data[marker.anim][0]*width" :x2="line1Data[marker.anim][0]*width" :y1="line1Data[marker.anim][1]*height" :y2="marker.iy*height"></line>
                                <image v-bind:class="{'show': animMarkerIndex > marker.anim}" width="300" height="80" :x="line1Data[marker.anim][0]*width-150" :y="marker.iy*height-40" :href="marker.src" />
                                <text v-if="false" class="label" v-bind:class="{'show': animMarkerIndex > marker.anim}" :x="line1Data[marker.anim][0]*width-15" :y="marker.iy*height" fill="#071b3c" font-size="15" font-weight="bold" v-html="marker.i"></text>
                                <circle v-bind:class="{'show': animMarkerIndex > marker.anim}" width="80" height="80" :cx="line1Data[marker.anim][0]*width" :cy="line1Data[marker.anim][1]*height" r="40" fill="#fde900"></circle>
                                <text class="label" v-bind:class="{'show': animMarkerIndex > marker.anim}" :x="line1Data[marker.anim][0]*width-15" :y="line1Data[marker.anim][1]*height+5" fill="#071b3c" font-size="15" font-weight="bold">{{marker.l}}</text>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AnimatedCounter from "../components/AnimatedCounter"

    export default {
        name: "GeneratieZon",

        components: {
            AnimatedCounter
        },
        props: {
            active: {
                type: Boolean,
                default: true
            },
            start: {
                type: Boolean,
                default: true
            },
            mobile: {
                type: Boolean,
                default : false
            }
        },
        data() {
            return {
                sourceUrl: "",
                sourceLogo: "",
                d: "",
                animDuration: 5,
                width: 800,
                height: 800,
                line1Data: [
                    [.2, 1-0.25],
                    [.4, 1-.3],
                    [.5, 1-.35],
                    [.65, 1-.5],
                    [.7, 1-.55],
                    [.85, 1-.9],
                ],
                markers: [
                    {l:"1989", src: '/images/eerste woning.svg', i:"Eerste woning met zonnepanelen in Nederland.", anim: 0, iy: 0.9},
                    {l:"2012", src: '/images/online dakscan.svg', i:"Sungevity introduceert online dakscan.", anim:2, iy: 0.9},
                    {l:"2020", src: '/images/1 miljoen.svg', i:"1 MILJOEN woningen met zonnepanelen.", anim:4, iy: 0.8},
                    {l:"2025", src: '/images/2 miljoen.svg', i:"2 MILJOEN Nederlandse huizen met zonnepanelen, een kwart van de samenleving!", anim:5, iy: 0.68}
                ],
                animMarkerIndex: 0
            }
        },
        mounted() {
            this.width = window.innerWidth+5;
            this.height = window.innerHeight+5;
        },
        computed: {
            viewbox: function() {
                return "-5 -5 "+this.width+" "+this.height;
            }
        },
        methods: {
            startAnim() {
                if(this.mobile) return;
                this.animMarkerIndex = 0;
                if(this.animInterval != null) {
                    clearInterval(this.animInterval);
                }
                let _this = this;
                this.animInterval = setInterval(function() {
                    _this.animMarkerIndex += 1;
                }, 1100);
            },
        },
        watch: {
            start(currentValue) {
                if(currentValue) {
                    this.startAnim();
                }
            },
            active(currentValue, oldValue) {
                if(currentValue != oldValue && currentValue == true) {
                     this.startAnim();
                }
                if(!currentValue) {
                    this.animMarkerIndex = -1;
                }
            }
        }
    }
</script>