<template>
    <div class="articles-title" v-bind:class="{shown: active}">
        <div class="content" style="opacity:0.4">
            <vue-typed-js v-if="active" :strings="['Ook<br/>hier<br/>maken<br/>wij<br/>ons<br/>hard<br/>voor']" :contentType="'html'" :typeSpeed="100">
                <h1 class="typing"></h1>
            </vue-typed-js>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ArticleComponent",

        components: {
            
        },
        methods: {

        },
        mounted() {
           
        },
        data() {
            return {
               
            }
        },
        props: {
            active: {
                type: Boolean,
                default: false
            },
        }
    }
</script>