<template>
    <div class="section article-component">
        <div class="slide">
            <div class="container content">
                <div class="row article" v-if="!article.header">
                    <div v-if="!mobile" class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-4 offset-lg-2 offset-xl-2 d-flex hidden-sm-up justify-content-center">
                        <div class="screenshot-container">
                            <div class="inner" :style="article.style ? article.style : ''">
                                <img :src="article.screenshot" alt="" class="screenshot img-fluid">
                                <div class="overlay">
                                    <a target="_blank" :href="article.sourceUrl">Bekijk het artikel</a>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-5 d-flex text-center ">
                        <div class="summary text-md-left">
                            <a v-if="article.sourceUrl" :href="article.sourceUrl" target="_blank">
                                <img :src="article.sourceLogo" class="source-logo mt-3" height="50"> 
                            </a>
                            <h1 v-bind:class="{'yellow' : light, 'panel-blue' : !light}" v-html="article.title"></h1>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="!article.header">
                    <div class="col-12 justify-content-center">
                        <div class="summary-body" v-bind:class="{'panel-blue' : !light, 'white' : light}" v-html="article.body">

                        </div>
                    </div>
                </div>
                <div class="row" v-if="article.header">
                    <div class="d-block d-md-none col-12">
                        <h1 class="orange">{{title}}</h1>
                    </div>
                    <div class="d-none d-none d-md-block d-lg-none col-12">
                        <vue-typed-js v-if="active" :strings="[title]" :showCursor="false" :contentType="'html'" :typeSpeed="100">
                            <h1 class="typing left"></h1>
                        </vue-typed-js>
                    </div>
                    <div class="d-none d-none d-lg-block col-md-4 col-lg-3">
                        <vue-typed-js v-if="active" :strings="[title.replace(' ', '<br/>')]" :showCursor="false" :contentType="'html'" :typeSpeed="100">
                            <h1 class="typing right"></h1>
                        </vue-typed-js>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-9 header">
                        <div class="summary align-self-lg-center">
                            <div class="summary-body panel-blue">
                                <p>{{article.body}}</p>
                            </div>
                            <a v-if="article.sourceUrl" :href="article.sourceUrl" target="_blank">
                                <img :src="article.sourceLogo" class="source-logo mt-3" height="50"> 
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ArticleItem",

        components: {

        },
        methods: {
        },
        data() {
            return {
                viewArticle: false,
                title: "Ook hier maken wij ons hard voor"
            }
        },
        props: {
            article: {
                type: Object,
            },
            light: {
                type: Boolean,
                default: false
            },
            active: {
                type: Boolean,
                default: false
            },
            mobile: {
                type: Boolean,
                default: false
            }
        }
    }
</script>