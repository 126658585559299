<template>
    <div class="section fp-auto-height-responsive huurdaken">
        <div class="slide" >
            <div class="container-fluid content">
                <div class="row px-3 pt-5 pt-md-0 pb-5 pb-md-0">
                    <div class="row col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 d-flex offset-md-1">
                        <div class="summary align-self-lg-center">
                            <h1>Zonnepanelen kwamen ook op huurdaken</h1>
                            <div class="summary-body">
                                <p>Waarom zouden alleen huiseigenaren mogen profiteren van zonnepanelen? Ook de sociale huurders kunnen het voordeel van zonnestroom goed gebruiken.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Huurdaken",

        components: {

        },
        methods: {
        },
        data () {
            return {
                
            }
        },
        props: {
        }
    }
</script>