<template>
    <div class="section fp-auto-height-responsive" id="nos-video">
        <div class="slide" >
            <div class="container-fluid content">
                <div class="row">
                    <div class="col-12">
                        <div class="summary align-self-lg-center" v-show="!toggler">
                            <h1>In <span>30 jaar</span> tijd is veel <br /> veranderd</h1>
                        </div> 
                    </div>
                </div>
            </div>

            <video-background
                ref="videobackground"
                class="background-video"
                v-bind:class="{shown: toggler}"
                :src="source.src"
                :muted="!toggler"
                :autoplay="false"
                @ready="videoLoaded"
                @error="videoError"
            ></video-background>
            <div class="play" @click="toggler = !toggler">
                <span v-if="!toggler"><img src="/images/play.svg" /></span>
                <span v-else><img src="/images/pause.svg" /></span>
            </div>
            <div class="background-overlay" v-show="!toggler"> </div>
        </div>
    </div>
</template>

<script>
    import VideoBackground from 'vue-responsive-video-background-player'

    import AnimatedCounter from '../components/AnimatedCounter'

    export default {
        name: "NOSVideo",

        components: {
            VideoBackground,
            AnimatedCounter
        },
        methods: {
            videoLoaded() {
                //this.$refs.videobackground.player.play();
            },
            videoError(e) {
                if(process.env.NODE_ENV === 'development') console.log(e)
            }
        },
        watch: {
            toggler(current) {
                if(this.toggler) {
                    this.$refs.videobackground.player.play();
                } else {
                    this.$refs.videobackground.player.pause();
                }
            },
            active(current, oldvalue) {
                if(current == true && oldvalue == false) {
                    this.years = 30;
                } else {
                    this.years = 0;
                    this.toggler = false;
                }
            }
        },
        data() {
            return {
                toggler: false,
                options: {

                },
                source: {
                    src: '/videos/sungevity_nos_3.mp4',
                    type: 'video/mp4'
                },
                type: "video",
                caption: '<h4>Monsters Inc.</h4>',
                width: 800, // required
                height: 600, // required
                autoplay: true, //Optional to autoplay video when lightbox opens,
                years: 0
            }
        },
        props: {
            page: {
                type: Object
            },
            impactUrl: {
                type: String
            },
            videoOptions: {
                type: Object
            },
            active: {
                type: Boolean,
                default: false
            }
        }
    }
</script>