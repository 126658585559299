const pages = {
    "intro" : {
        title: "Intro",
        state: 0,
        bEarth: true,
        orangeLogo: true,
        orangeLogoMobile: true
    },
    "welkom" : {
        title: "Welkom",
        state: 0,
        bEarth: true,
        orangeLogo: true,
        orangeLogoMobile: true
    },
    "persoonlijke-impact" : {
        title: "Persoonlijke impact",
        state: 5,
        active: false,
        bEarth: true,
        orangeLogo: true,
        orangeLogoMobile: true
    },
    "onze-impact-bomen": {
        title: "Onze impact bomen",
        state: 10,
        bEarth: true,
        orangeLogo: true,
        orangeLogoMobile: false,
        metric: 0
    },
    "onze-impact-vliegen": {
        title: "Onze impact vliegen",
        state: 11,
        bEarth: true,
        orangeLogo: true,
        orangeLogoMobile: true,
        metric: 1
    },
    "onze-impact-autorijden": {
        title: "Onze impact autorijden",
        state: 12,
        bEarth: true,
        orangeLogo: true,
        orangeLogoMobile: true,
        metric: 2
    },
    "generatie-zon": {
        title: "Generatie zon",
        state: 12,
        bEarth: false,
        masonry: true,
        orangeLogo: false,
        orangeLogoMobile: false,
    },  
    "generatie-zon-groeit": {
        title: "Generatie zon groeit",
        state: 12,
        bEarth: false,
        masonry: true,
        removeNav: true,
        parent: 'generatie-zon',
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "generatie-zon-groeit-door": {
        title: "Generatie zon groeit door",
        state: 12,
        bEarth: false,
        masonry: true,
        removeNav: true,
        parent: 'generatie-zon',
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "1-miljoen-huizen": {
        title: "1 Miljoen huizen",
        state: 0,
        bEarth: false,
        background: {
            type: 1, //Video
            options: {
                src: '/videos/output.mp4',
                poster: '/images/roebyem-nos.jpg'
            }
        },
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "sociale-huur": {
        title: "Sociale huur",
        state: 0,
        bEarth: false,
        background: {
            type: 0, //Image
            options: {
                src: '/images/sungevity-huizen-met-zonnepanelen.jpg'
            }
        },
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "sociale-huur-2": {
        title: "sociale huur 2",
        state: 0,
        bEarth: false,
        removeNav: true,
        parent: 'sociale-huur',
        background: {
            type: 0, //Image
            options: {
                src: '/images/sungevity-huizen-met-zonnepanelen.jpg'
            }
        },
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "impact-maken": {
        title: "Impact maken",
        state: 0,
        bEarth: false,
        bArticlesTitle: false,
        background: {
            type: 0,
            options: {
                src: '/images/ookhiermakenwijonshardvoor.jpg',
                overlay: 'yellow'
            }
        },
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "recyclen": {
        title: "Recyclen",
        state: 0,
        bEarth: false,
        bArticlesTitle: true,
        background: {
            type: 0,
            options: {
                src: '/images/recyclen-bg.jpg',
                overlay: 'green'
            }
        },
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "huurhuizen": {
        title: "Huurhuizen",
        state: 0,
        bEarth: false,
        bArticlesTitle: true,
        background: {
            type: 0,
            options: {
                src: '/images/veiligeinstallatie.jpg',
                overlay: 'panel-blue'
            }
        },
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "huur-is-duur": {
        title: "Huur is duur",
        state: 20,
        bEarth: false,
        bArticlesTitle: true,
        background: {
            type: 0,
            options: {
                src: '/images/huur-is-duur.jpg',
                overlay: 'orange'
            }
        },
        orangeLogo: false,
        orangeLogoMobile: false,
    },
    "toekomst": {
        title: "Toekomst",
        state: 20,
        bEarth: true,
        orangeLogo: true,
        orangeLogoMobile: false,
    }
};
/*
backgrounds: [
    {
        backgroundUrl: "",
        poster: "./images/roebyem-nos.jpg",
        backgroundVideo: "./videos/output.webm",
        sourceLogo: "./images/nos-white.svg",
        sourceUrl: "https://nos.nl/artikel/2345132-aantal-huizen-met-zonnepanelen-tikt-1-miljoen-aan.html"
    },
    {
        backgroundUrl: "./images/sungevity-huizen-met-zonnepanelen.jpg",
        sourceLogo: "/images/change-inc.svg",
        sourceUrl: "https://www.change.inc/infra/zonnepanelen-woningcorporaties-35177"
    },
    {
        backgroundUrl: "",
        backgroundVideo: "videos/solar-drone.webm",
        poster: "./images/droneshot.jpg",
        sourceLogo: "",
        sourceUrl: ""
    },
    {
        // backgroundUrl: './images/nrc-dak-ligt-een-probleem.jpg',
        backgroundUrl: ""
    },
    {
        // backgroundUrl: "./images/nrc-recyclen.jpg",
        backgroundUrl: ""
    },
    {
        // backgroundUrl: "./images/sungevity-installatie.jpg",
        backgroundUrl: ""
    },
],*/

export default pages