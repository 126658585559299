<template>
    <div class="section conclusion">
        <div class="slide">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6 panel-blue" v-if="!visitor">
                            <h1 class="toekomst">De toekomst is <br/> <span class="zonnig">zonnig</span></h1>
                            <p class="bold">
                                Wist je dat de hoeveelheid zon die in twee minuten op de aarde schijnt
                                genoeg is om de wereld een jaar lang van stroom te voorzien?
                            </p>
                            <p class="bold">
                                En als iedereen zonnestroom gebruikt, lossen we een kwart van ons CO₂-probleem op!
                            </p>
                            <p class="bold">
                                Dus waar wachten we nog op?
                                Zonnestroom moet de norm worden. En snel!
                            </p>
                            <p class="bold">
                                We zijn goed op weg. En met jouw hulp gaat het ons zeker lukken. 
                            </p>
                            <p class="bolder">
                                Welkom bij Generatie Zon
                            </p>
                            <a class="btn btn-primary btn-lg" href="https://www.sungevity.nl/over-sungevity" target="_blank">Lees meer over Sungevity</a>
                    </div>
                    <div class="col-sm-12 col-md-6 panel-blue" v-else-if="visitor && visitor.getType() == 0">
                        <h1 class="toekomst">De toekomst is <br/> <span class="zonnig">zonnig</span></h1>
                        <p class="bold">Dankjewel voor jouw rol in de energietransitie, {{ visitor.getName() }}. Dankzij jouw keuze om over te stappen op zonnestroom, is de ‘zonnesneeuwbal’ in beweging gekomen. En hij is niet meer te stoppen! </p>
                        <p class="bold">
                            <strong>
                                Deel € 400,- vriendenkorting uit!
                            </strong>
                            <br />
                            Gun jij jouw vrienden ook de voordelen van zonnestroom? Dan is dit het moment om te trakteren. Als Sungevity-klant mag jij maar liefst 400 euro korting weggeven op een zonnesysteem van Sungevity!
                        </p>
                    </div>
                    <div class="col-sm-12 col-md-6 panel-blue" v-else-if="visitor && visitor.getType() == 1">
                        <h1 class="toekomst">De toekomst is <br/> <span class="zonnig">zonnig</span></h1>
                        <p class="bold">Dankjewel voor jouw rol in de energietransitie, {{ visitor.getName() }}. Door jou is de zonnesneeuwbal in beweging gekomen, en hij is niet meer te stoppen.</p>
                        <p class="bold">
                            Als iedereen zonnestroom gebruikt, 
                            <br />
                            lossen we een kwart van ons CO₂-probleem op.
                            <br />
                            Zonnestroom moet de norm worden. En snel! 
                        </p>
                        <p>
                            We zijn goed op weg. En met jouw hulp gaat het ons zeker lukken.
                        </p>
                        <p class="bolder">
                            Welkom bij Generatie Zon
                        </p>
                    </div>
                    <div class="col-sm-12 col-md-6 panel-blue referral-column" v-if="visitor && visitor.getType() != 1">
                        <p class="bold">
                            <strong>Hoe werkt het?</strong>
                            <br/>
                            Stuur je vrienden deze kortingscode. Als ze de code invullen bij de adviesaanvraag, wordt de korting geactiveerd bij aankoop.
                        </p>
                        <div class="d-flex-t">
                            <input type="text" value="" id="referralcode-value" class="text-center">
                            <button class="btn btn-light btn-large referralcode-btn " data-clipboard-target="#referralcode-value">
                                <svg v-if="!refCopied" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                </svg>

                                <svg v-if="refCopied" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                                </svg>
                            </button>
                        </div>
                        <SocialShareButton :left="true" v-if="visitor" style="margin-top: 1em;" :normal="false" @click="share = true" label="Deel je <br/> korting!"></SocialShareButton>
                    </div>
                </div>
            </div>
        </div>
        <ShareScreen v-if="share" :social="refSocial" @close="share = false"></ShareScreen>
    </div>
</template>

<script>
import Visitor from '../components/Visitor'
import ClipboardJS from 'clipboard'

import SocialShareButton from './SocialShareButton'
import ShareScreen from './ShareScreen'

export default {
    name: "Conclusion",
    components: {
        ClipboardJS,
        SocialShareButton,
        ShareScreen
    },
    data () {
        return {
            refCode: "", //this is used for the social sharing texts
            refSocial: {
                url: "",
                title: "",
                description: ""
            },
            refCopied: false,
            share: false,
        }
    },
    watch: {
        active(currentValue, oldValue) {
            if(currentValue != oldValue && !currentValue) {
                this.share = false;
            }
        }
    },
    props: {
        visitor: {
          type: Visitor,
          default: null
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        let _this = this;

        if(this.visitor != null) {
            if(this.visitor.getLeadNumber() < 1) {
                this.refCode = "IMPACT-"+this.visitor.getName(); 
            } else {
                if(this.visitor.getType() != 1) {
                    document.getElementById("referralcode-value").value = this.visitor.getReferralCode();
                    this.refCode = this.visitor.getReferralCode()
                }
            }
        } else {
            this.refCode = "IMPACT"
        }

        // code to copy the referralcode to the user's clipboard. After 3 seconds, 'refCopied' is set back to false, so that the image on the UI is reverted back as well
        var clipboard = new ClipboardJS('.referralcode-btn');
        clipboard.on('success', function(e) {
            _this.refCopied = true;
            setTimeout(function() {
                _this.refCopied = false;
            }, 3000)
        });

        // change the text for social media, based on the whether the visitor is a customer client or not
        if(this.visitor != null) {
            this.refSocial.title = "Geef €400 korting op een zonnesysteem";
            this.refSocial.description = "Ik heb zonnepanelen bij Sungevity. Sungevity heeft nu een actie waarmee ik jou €400 vriendenkorting kan geven. Ontvang een gratis dakscan via https://www.sungevity.nl/advies en gebruik de kortingscode: " + this.refCode;
            return this.refSocial.title, this.refSocial.description
        } else {
            this.refSocial.title = "Geef €400 korting op een zonnesysteem";
            this.refSocial.description = "Ik heb zonnepanelen bij Sungevity en dat gun ik jou ook. Ik mag jou €400,- vriendenkorting geven! Vraag gratis en vrijblijvend advies aan via https://www.sungevity.nl/advies en gebruik de kortingscode: " + this.refCode;
            return this.refSocial.title, this.refSocial.description
        }
    },
}
</script>