<template>
    <div class="section fp-auto-height-responsive generatie-zon">
        <div class="slide" >
            <div class="container-fluid content">
                <div class="row">
                    <div class="col col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 d-flex offset-md-1">
                        <div class="summary align-self-lg-center">
                            <h1>Generatie Zon Groeit</h1>
                            <div class="summary-body">
                                <p><b>We zitten al op de helft: 1 miljoen daken!</b><br />
                                    1 miljoen Nederlandse huizen hebben zonnepanelen op het dak. Dat betekent dat één op de acht mensen al leeft op zonnestroom. Als iedereen één buurman inspireert, zijn we er al! Hoopgevend, nietwaar? </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AnimatedCounter from "../components/AnimatedCounter"

    export default {
        name: "GeneratieZon",

        components: {
            AnimatedCounter
        },

        methods: {
            
        },

        data() {
            return {
                sourceUrl: "",
                sourceLogo: "",
            }
        },
        
        props: {
            active: {
                type: Boolean,
                default: true
            }
        }
    }
</script>