<template>
    <div class="masonry-faker" ref="masonry" v-bind:class="{'fade-out': visible, 'fade-in': visible, 'soft': soft }">
        <div class="background-container" v-bind:class="{zoom: zooming, 'zoom-fast': !zooming}">
            <img src="/images/masonry-faker-bg.jpg" />
            <div class="background-overlay"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MasonryComponent",
    data () {
        return {
            
        }
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        zooming: {
            type: Boolean,
            default: false
        },
        soft: {
            type: Boolean,
            default : false
        }
    },
    mounted() {
        
    },
    destroy() {
        
    },
    methods: {
        
    },
    computed: {
        
    },
}
</script>