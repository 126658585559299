class Visitor {
    constructor(name, userType){
       this.name = name;
    //    this.leadNumber = leadNumber;
       this.userType = userType;
    }

    getType() {
        // 0 is customer, 1 is partner
        return this.userType;
    }

    getName(){
        return this.name;
    }

    getLeadNumber() {
        return this.leadNumber;
    }

    getReferralCode() {
        return "IMPACT2021";
    }

    setKWH(kWh) {
        this.kWh = parseInt(kWh);
    }

    getKWH() {
        return this.kWh;
    }

    static fN(num) {
        return new Intl.NumberFormat('de-DE').format(num);
    }

    getCO2() {
        return (this.kWh*0.475);
    }
};

export default Visitor