<template>
    <div class="section total-impact">
        <div class="slide">
            <div class="container" :tag="metric">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6" v-bind:class="{'offset-md-6': metric == 0 || metric == 2}">
                        <div v-if="header && metric===0" class="card card-body special-card">
                            <h1><b>Onze impact samen</b></h1>
                            <p>Al onze zonnesystemen samen hebben tot en met vorig jaar <strong>298 miljoen kWh</strong> opgewekt.</p>
                            <p>Daarmee hebben we <strong>123.924 ton CO<sub>2</sub></strong> bespaard.</p>
                            <p>Ter vergelijking: elke ton CO<sub>2</sub> staat gelijk aan 1 boom.</p>
                        </div>

                        <div v-if="header && metric===1" class="card card-body special-card">
                            <h1><b>60.000 RETOURTJES NEW-YORK</b></h1>
                            <p>Van 123.924 ton CO2 kun je ook bijna 60 duizend retourvluchten van Amsterdam naar New-York maken. Ofwel 360 duizend uur vliegen.</p>
                            <p>Zelfs de meest geboekte DJ gaat dit niet volmaken in één leven.</p>
                        </div>

                        <div v-if="header && metric===2" class="card card-body special-card">
                            <h1><b>40.000 keer de aarde rond</b></h1>
                            <p>Of 1,6 miljard kilometer autorijden op fossiele brandstof. Daarvoor kun je (grof geschat) zo’n 40.000 keer de aarde rond.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnimatedCounter from '../components/AnimatedCounter'

export default {
    name: "TotalImpact",
    components: {
        AnimatedCounter
    },
    props: {
        metric: {
            type: Number,
            default: 0
        },
        header: {
            type: Boolean,
            default: true
        }
    },
    
}
</script>