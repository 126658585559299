<template>
    <div class="section greeting">
        <div class="welcome col-sm-12">
            <transition-group name="fade" v-if="visitor != null">
                <h1 :key="1" v-if="show >= 1">Welkom</h1>
                <h1 :key="2" v-if="show >= 2" class="name">{{ visitor.name }}</h1>
                <h1 :key="3" v-if="show >= 3">bij jouw <br /><span>persoonlijke</span></h1>
                <h1 :key="4" v-if="show >= 4" class="impact">Impact Report</h1>
            </transition-group>
            <transition-group name="fade" v-else>
                <h1 :key="1" v-if="show >= 1">Sungevity</h1>
                <h1 :key="2" v-if="show >= 2">Impact</h1>
                <h1 :key="3" v-if="show >= 3">Report</h1>
            </transition-group>
        </div>
        <div class="scroll-aid">
            <div class='icon-scroll'></div>
        </div>
    </div>
</template>

<script>
import Visitor from '../components/Visitor'

export default {
    name: "GreetingComponent",
    props: {
        visitor: {
            type: Visitor,
            default: null
        },
        active: {
            type: Boolean,
            default: false
        },
        start: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        active(currValue, oldValue) {
            if(process.env.NODE_ENV === 'development') console.log([currValue, oldValue]);
            if(currValue == true && oldValue == false) {
                this.startGreeting();
            } else {
                if(this.greetInterval) clearInterval(this.greetInterval);
                this.show = 0;
            }
        },
        start(currValue, oldValue) {
            if(currValue) {
                this.startGreeting();
            }
        }
    },
    methods: {
        startGreeting() {
            if(this.greetInterval) clearInterval(this.greetInterval);
            this.greetInterval = setInterval(() => {
                this.show++;
            }, 750); //changed this from 1000 to 750 based on Eveline's feedback
        }
    },
    data() {
        return {
            greetInterval: null,
            show: 0
        };
    },
}
</script>