<template>
    <div id="codingdelta">
        <div class="container-fluid">
            <a class="row d-flex" href="https://codingdelta.com" target="_blank">
                <img src="/images/CodingDelta-white.svg" class="logo-container" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "CodingDeltaComponent",
}
</script>


<style scoped>
#codingdelta {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
}
.logo-container {
    height: 20px;
    margin: 1em;
    box-sizing: border-box;
    position: fixed;
    bottom:0;
    left:0;
    
}
</style>