const articles = [
    {
        header: true,
        title: "",
        body: "Als we zonnestroom de norm willen maken (en dat willen we!), moet de hele zonnestroomsector hoge kwaliteit leveren. Politiek beleid is hierop van grote invloed. Daarom maakt Sungevity zich regelmatig hard voor betere regelgeving.",
    },
    {
        header: false,
        title: "Recyclen",
        titleColor: "",
        body: "<p>We riepen bijvoorbeeld op tot een beter recyclingbeleid, om te voorkomen dat we over 20 jaar met een enorme berg afgedankte zonnepanelen zitten. <br /> <br /> <i>Naar aanleiding van dit artikel in NRC Handelsblad  werden Kamervragen gesteld. </i></p>",
        sourceLogo: "/images/nrc-logo.svg",
        sourceUrl: "https://www.nrc.nl/nieuws/2019/12/16/leg-recyclingkosten-zonnepaneel-bij-koper-a3984002",
        screenshot: "/images/screenshot-recyclen-small.png"
    },
    {
        header: false,
        title: "ZORG DAT HUURHUIZEN SNELLER VERDUURZAMEN",
        style: "margin-top: 2em",
        titleColor: "",
        body: "<p>Dat schreven Roebyem Anders, Sietse Jager en Harry Platte. Met verduurzaming gaan de woonlasten voor de huurders omlaag én komen klimaatdoelen in zicht. Win-win, dus. Toch helpt het beleid niet mee. Tijd voor actie, want deze kans is toch te mooi om te laten liggen?</p>",
        sourceLogo: "/images/fd-logo.png",
        sourceUrl: "https://fd.nl/opinie/1351033/zorg-dat-huurhuizen-sneller-kunnen-verduurzamen",
        screenshot: "/images/Sungevity-fd-artikel.png"
    },
    {
        header: false,
        title: "WAARSCHUWING: huren is duur",
        titleColor: "",
        body: "<p>Niet iedereen kan zomaar drieduizend euro neerleggen. Daarom kiezen veel mensen ervoor om zonnepanelen te huren, maar dat is onnodig duur. Wie geen eigenaar is van de panelen, loopt namelijk veel voordeel mis*. Wij waarschuwen mensen in dit AD-artikel en wijzen de lezer direct op een betere optie: zonnepanelen kopen met een groene lening.</p><p class='small-text'><sup>*</sup>Dit geldt niet voor huurders van woningcorporaties, omdat de woningcorporatie de panelen koopt en het voordeel doorrekent.</p>",
        sourceLogo: "/images/algemeen-dagblad-vector.svg",
        sourceUrl: "https://www.ad.nl/wonen/snelle-groei-zonnepanelen-zet-door-als-je-ze-niet-hebt-ben-je-dief-van-je-eigen-portemonnee~aec2d7a4/",
        screenshot: "/images/screenshot-ad-small.png"
    }
];

export default articles