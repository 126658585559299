<template>
    <div class="background-switch">
        <div class="background-container" v-bind:class="{'fade-out': backgrounds == null, 'fade-in': backgrounds != null }" >
            <div class="background-wrapper" v-bind:class="{'fade-out': !index == select, 'fade-in': index == select }" v-for="(background, index) in backgrounds" :key="index">
                <div v-if="background.type == 0" class="background" :style="{ backgroundImage: 'url(' + background.options.src + ')' }"></div>
                <video-background v-if="background.type == 1" class="background-video"
                    :src="background.options.src"
                    :poster="background.options.poster"
                    style="height: 100vh; position:absolute"
                    :muted="true"
                >
                </video-background>
                <div v-if="background.options.overlay === undefined" class="background-overlay"></div>
                <div v-else :class="`background-custom-${background.options.overlay}`"></div>
            </div>
        </div>
    </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'

export default {
    name: "BackgroundSwitcher",
    components: {
        VideoBackground
    },
    props: {
        backgrounds: {
            type: Object,
            default: null
        },
        select: {
            type: String,
            default: ''
        }
    },
    data () {
      return {
        
      };
    },
    mounted(){
        if(process.env.NODE_ENV === 'development') console.log(this.backgrounds);
    },
    watch: {
        
    },
}
</script>