<template>
    <div v-if="visitor && visitor.getType() == 0">
        <h1><b>Beste {{ visitor.getName() }},</b></h1>
        <p>
            Dat je met zonnepanelen bijdraagt aan een beter milieu, dat weet je. Maar hoe zit dat precies? Hoeveel CO<sub>2</sub> help jij uit de lucht te houden?
        </p>
        <p>
            Dat lees je in jouw persoonlijke Impact Report. Naast jouw bijdrage als individu, zie je ook hoeveel goed je doet samen met andere zonnepanelenbezitters. Want Generatie Zon is hard gegroeid. En samen slaan we een flinke deuk in de CO<sub>2</sub>-uitstoot. 
        </p>
        <p>
            <span style="font-weight: 800">SHINE ON!</span>
            Team Sungevity
        </p>
    </div>
    <div v-else-if="visitor && visitor.getType() == 1">
        <h1><b>Beste {{ visitor.getName() }},</b></h1>
        <p>
            Dat je met zonnepanelen bijdraagt aan een beter milieu, dat weet je. Maar hoe zit dat precies? Hoeveel CO<sub>2</sub> help jij uit de lucht te houden?
        </p>
        <p>
            Dat lees je hier. Naast jouw bijdrage als organisatie, zie je ook hoeveel goed al onze klanten en partners samen doen.
        </p>
        <p>
            Want Generatie Zon is hard gegroeid. En samen slaan we een flinke deuk in de CO<sub>2</sub>-uitstoot.
        </p>
        <p>
            <span style="font-weight: 800">SHINE ON!</span>
            Team Sungevity
        </p>
    </div>
    <div v-else>
        <h1><b>Sungevity Impact Report</b></h1>
        <p>
            Als missiegedreven organisatie is impact onze belangrijkste graadmeter voor succes. Met ons Impact Report maken we de balans op: welke impact, positief én negatief, maken wij als organisatie? Hoeveel mensen hebben wij aan zonnestroom geholpen? En hoeveel CO<sub>2</sub> hebben we samen uit de lucht gehouden?
        </p>
        <p>
            Scrol door de hoogtepunten of <a :href="impactUrl" target="_blank">lees het volledige Impact Report hier</a>. 
        </p>
        <p>
            <span style="font-weight: 800">SHINE ON!</span>
            Team Sungevity
        </p>
    </div>
</template>

<script>
import Visitor from '../components/Visitor'

export default {
    name: "WelcomeSummary",
    props: {
        visitor: {
            type: Visitor,
            default: null
        }
    },
    data () {
        return {
            impactUrl: 'https://www.sungevity.nl/wp-content/uploads/2021/02/impactreport-2020.pdf'
        }
    },
    methods: {
        
    },
}
</script>