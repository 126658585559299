<template>
    <div id="welcome" class="section">
        <div class="slide">
            <div class="container content">
                <div class="row">
                    <div v-if="!mobile" class="col col-sm-12 order-sm-12 order-md-1 col-md-8 order-lg-1 col-lg-6">
                        <WelcomeTextComponent :visitor="visitor" />
                    </div>
                    <div class="col col-sm-12 order-sm-1 col-md-4 order-lg-12 col-lg-6">
                        <div v-if="logoAnimFrame <= 5 || !mobile" class="text-center d-flex flex-column justify-content-center">
                            <div class="sun anim" v-bind:class="{fade: logoAnimFrame > 0}">
                                <span class="d-block" style="font-weight: 800">SUN</span>
                                <span class="d-block">(ZON)</span>
                            </div>
                            <div class="plus anim math-sign align-middle" v-bind:class="{fade: logoAnimFrame > 1}">
                                +
                            </div>
                            <div class="longevity anim" v-bind:class="{fade: logoAnimFrame > 2}">
                                <span class="d-block" style="font-weight: 800">LONGEVITY</span>
                                <span class="d-block">(LANG LEVEN)</span>
                            </div>
                            <div class="equal anim math-sign align-middle" v-bind:class="{fade: logoAnimFrame > 3}">
                                =
                            </div>
                            <div class="sungevity anim" v-bind:class="{fade: logoAnimFrame > 4}">
                                <span style="font-weight: 800">SUNGEVITY</span>
                            </div>
                        </div>
                        <WelcomeTextComponent class="welcome-mobile-content" v-if="mobile && logoAnimFrame > 5" :visitor="visitor" v-bind:class="{fade: logoAnimFrame > 6}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Visitor from '../components/Visitor'
import WelcomeTextComponent from './WelcomeTextComponent'

export default {
    name: "WelcomeSummary",
    components: {
        WelcomeTextComponent
    },
    props: {
        visitor: {
            type: Visitor,
            default: null
        },
        active: {
            type: Boolean,
            default: false
        },
        start: {
            type: Boolean,
            default: false
        },
        mobile: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.startAnim();
    },
    watch: {
        active(currentVal, oldVal) {
            if(currentVal != oldVal){
                this.startAnim();
            }
        },
        start(currentVal, oldVal) {
            if(currentVal != oldVal){
                this.startAnim();
            }
        }
    },
    data () {
      return {
          logoAnimFrame: 0,
          logoAnimInterval: null,
          impactUrl: "https://www.sungevity.nl/wp-content/uploads/2021/02/impactreport-2020.pdf"
        }
    },
    methods: {
        startAnim() {
            this.logoAnimFrame = 0;
            if(this.logoAnimInterval != null) {
                clearInterval(this.logoAnimInterval);
            }
            let _this = this;
            this.logoAnimInterval = setInterval(function() {
                _this.logoAnimFrame += 1;
            }, !this.mobile ? 500 : 750);
        }
    },
}
</script>