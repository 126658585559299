<template>
    <div id="personal-impact" class="section">
        <div class="slide" >
            <div class="container">
                <div class="row">
                  <div v-if="visitor.getType() == 0" class="col">
                    <div class="personal-impact d-flex flex-column align-items-center">
                      <h1><b>Jouw persoonlijke impact</b></h1>
                      <p>Je hebt <span class="highlight">{{co2}} kg CO<sub>2</sub></span> bespaard met je zonnepanelen.</p>
                      <p>Dit staat gelijk aan:</p>
                    </div>
                  </div>
                  <div v-if="visitor.getType() == 1" class="col">
                    <div class="personal-impact d-flex flex-column align-items-center">
                      <h1><b>De impact van {{ visitor.getName() }}</b></h1>
                      <p>Jouw achterban heeft <span class="highlight">{{co2}} kg CO<sub>2</sub></span> bespaard met zonnepanelen van Sungevity.</p>
                      <p>Dit staat gelijk aan:</p>
                    </div>
                  </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-12 col-md-12 col-lg-3 d-flex flex-column align-items-center personal-metric" v-bind:class="{show: popup > index}" style="margin-bottom: 100px;" v-for="(personalMetric, index) in personalMetrics" :key="index">
                      <div class="circle">
                        <img :src="personalMetric.image" alt="" class="gif">
                      </div>
                      <div class="embleem">
                          <img src="/images/embleem-up.svg" alt="">
                          <div class="metric">
                            <h1><AnimatedCounter :reset="true" :value="parseInt(personalMetric.value)" :formatter="formatter" /></h1>
                            <p>{{personalMetric.text}}</p>
                          </div>
                      </div>
                    </div>
                </div>
                <SocialShareButton :normal="true" @click="share = true"></SocialShareButton>
            </div>
        </div>
        <ShareScreen v-if="share" :social="social" @close="share = false"></ShareScreen>
    </div>
</template>

<script>
import Visitor from '../components/Visitor'
import ShareScreen from './ShareScreen'
import SocialShareButton from './SocialShareButton'

import AnimatedCounter from '../components/AnimatedCounter'

export default {
    name: "PersonalImpact",
    components: {
      ShareScreen,
      SocialShareButton,
      AnimatedCounter,
    },
    props: {
        visitor: {
          type: Visitor,
          default: null
        },
        active: {
          type: Boolean,
          default: false
        },
        start: {
          type: Boolean,
          default: false
        }
    },
    watch: {
      active(currValue, oldValue) {
        if(currValue == true && oldValue == false) {
          this.animateMetrics();
        }

        if(currValue != oldValue && !currValue) {
            this.share = false;
        }
      },
      start(currValue) {
        if(currValue) {
          this.animateMetrics();
        }
      }
    },
    data () {
      return {
        social: {

        },
        popup: 0,
        share: false,
        popupInterval : null,
        personalMetrics: [
            {
                value: 0,
                text: "wasjes op 40 graden",
                image: "/images/gif/wash.gif"
            },
            {
                value: 0,
                text: "maanden je haar föhnen",
                image: "/images/gif/blowdry.gif"
            },
            {
                value: 0,
                text: "koppen koffie",
                image: "/images/gif/coffee.gif"
            },
        ],
        formatter: (a) => {
          return Visitor.fN(a.toFixed(0));
        }
      }
    },
    computed: {
      co2() {
        return this.visitor ? Visitor.fN(this.visitor.getCO2().toFixed(0)) : 0;
      }
    },
    methods: {
        formatDateTime: function(timestamp) {
            var x=new Date(timestamp);
            var dd = x.getDate();
            var mm = x.getMonth()+1;
            var yy = x.getFullYear();

            return dd + "-" + mm + "-" + yy;
        },
        fullPath: function() {
            return window.location.origin + this.$route.path;
        },
        animateMetrics() {
          let _this = this;
          clearInterval(this.popupInterval);
          this.popup = 0;
          this.popupInterval = setInterval(function() {
            _this.popup += 1;
          }, 800);
        },
    },
    mounted() {
      this.social.title = "Mijn impact dankzij mijn zonnepanelen";
      this.social.url = "";
      this.social.description = "Dit wil ik even met je delen: moet je zien hoeveel impact ik maak met mijn zonnepanelen! " + this.fullPath() ;
    },
    created() {
        this.personalMetrics[0].value = (this.visitor.getKWH() * 1000 / 356).toFixed(0);
        this.personalMetrics[1].value = (this.visitor.getKWH() * 1000 / (35 * 60 * 24 * 365 / 12)).toFixed(0);
        this.personalMetrics[2].value = (this.visitor.getCO2() * 1000 / 26.9).toFixed(0);
        if(process.env.NODE_ENV === 'development') console.log(this.personalMetrics);
    }
}
</script>